import {createEmailSubscription} from "../../../../services/email-subscription-services";
import {notificationError} from "../../../../utils/notification-error";
import {i18Translate} from "../../../../utils/i18-init"

window.onload = () => {

    const indexSidebarSubscribe = document.querySelector('.index-sidebar-subscribe')

    if (!indexSidebarSubscribe) return false

    indexSidebarSubscribe.querySelector('button').addEventListener('click', e => {
        e.preventDefault()

        createEmailSubscription(
            indexSidebarSubscribe.querySelector('input').value
        ).then(e => {
            notificationError(i18Translate('email-subscription.your-subscription-has-been'))
            indexSidebarSubscribe.querySelector('input').value = ''
        })
    })

}
